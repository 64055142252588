/** 
 * @Desc: 首页内容
 */
<template>
  <div class="page-wrapper-box">
    <div class="home-left">
      <div
        class="left-item"
        v-if="!userSystemHide"
        v-for="(menu, index) in leftMenuList"
        :key="index"
        @click="routerChange(menu, index, 'left')"
      >
        <!-- <img class="img-left-item cursor" :src="menu.img" alt=""> -->
        <!-- <div class="img-left-box"> -->
        <div class="img-left-item cursor"></div>
        <!-- </div> -->
        <p class="p-left-item cursor">{{menu.title}}</p>
        <div class="ageType" v-if="index == 2">
          <img v-show="age == 0" src="../../assets/new_ui/0.png" alt="">
          <img v-show="age == 2" src="../../assets/new_ui/2.png" alt="">
          <img v-show="age == 3" src="../../assets/new_ui/3.png" alt="">
          <img v-show="age == 4" src="../../assets/new_ui/4.png" alt="">
        </div>
      </div>
    </div>
    <div class="home-page-wrapper">
      <div class="home-right">
        <img class="tree_img" src="../../assets/new_ui/tree_980.png" alt="">
        <div
          class="type-block"
          :class="'type-block-' + menu.pageType"
          v-for="(menu, index) in menuList"
          :key="index"
          @click="routerChange(menu, index, 'right')"
        >
          <img class="img-type-block cursor" :src="menu.img_hom" alt="">
        </div>
      </div>
      <!-- 选择年龄段 -->
      <AgeGroupDialog
        :visible="ageDialogVisible"
        @handleClick="_closeAgeDialog"
        @ageType="ageType"
      />
      <!-- 登录 注册 找回密码 -->
      <LoginRegisterPwdDialog
        :visible="loginDialogVisible"
        component="login"
        @handleClick="_closeLoginDialog"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Cookies from "js-cookie";
import { sessionStorageUtil } from "utils/util";
import AgeGroupDialog from "components/age-group-dialog";
import LoginRegisterPwdDialog from "components/login-register-pwd-dialog";
export default {
  components: {
    AgeGroupDialog,
    LoginRegisterPwdDialog
  },
  inject: ["reload"],
  data() {
    return {
      leftMenuList: [
        {
          title: "最近观看",
          path: "/personal-center/history-record",
          module: "HOME",
          img: require("../../assets/new_ui/new_home_history.png")
        },
        {
          title: "我的收藏",
          path: "/personal-center/collection",
          module: "HOME",
          img: require("../../assets/new_ui/new_home_collection.png")
        },
        {
          title: "宝宝年龄",
          path: "",
          module: "HOME",
          img: require("../../assets/new_ui/new_home_age_group.png")
        }
      ],
      menuList: [
        {
          pageType: "12156",
          title: "情商小达人",
          path: "/12156",
          module: "HOME",
          haveIndexOf: "/12156",
          img_hom: require("../../assets/new_ui/new_1_home.png"),
          fontColor: "#f9a9a5"
        },
        {
          pageType: "12158",
          title: "神奇想象力",
          path: "/12158",
          module: "HOME",
          haveIndexOf: "/12158",
          img_hom: require("../../assets/new_ui/new_2_home.png")
        },
        {
          pageType: "12157",
          title: "小小科学家",
          path: "/12157",
          module: "HOME",
          haveIndexOf: "/12157",
          img_hom: require("../../assets/new_ui/new_3_home.png")
        },
        {
          pageType: "12155",
          title: "动物的秘密",
          path: "/12155",
          module: "HOME",
          haveIndexOf: "/12155",
          img_hom: require("../../assets/new_ui/new_4_home.png")
        },
        {
          pageType: "12159",
          title: "世界小公民",
          path: "/12159",
          module: "HOME",
          haveIndexOf: "/12159",
          img_hom: require("../../assets/new_ui/new_5_home.png")
        }
      ],
      ageDialogVisible: false,
      loginDialogVisible: false,
      age: 0,
      userSystemHide: false
    };
  },
  computed: {
    ...mapState(["accountInfo", "isLogin"])
  },
  methods: {
    ageType(item) {
      console.log(item, "年龄");
      this.age = item;
    },
    /**
     * 页面reload
     * **/
    _relaod() {
      this.reload();
    },
    /**
     * 路由变化
     * **/
    routerChange(item, index, type) {
      console.log(item, "路由变化");
      // 获取当前页面文字颜色

      if (
        !this.isLogin &&
        type === "left" &&
        item.path !== "/personal-center/history-record"
      ) {
        this.loginDialogVisible = false;
        setTimeout(() => {
          this.loginDialogVisible = true;
        }, 100);
        return;
      }
      sessionStorageUtil.removeItem("fromType");
      if (item.path) {
        if (type === "right") {
          sessionStorageUtil.setItem("fromType", "home");
        }
        // this.$router.push(item.path);
        this.$router.push({ path: item.path, color: item.fontColor });
      } else {
        this.ageDialogVisible = false;
        setTimeout(() => {
          this.ageDialogVisible = true;
        }, 100);
      }
    },
    /**
     * 关闭宝宝年龄
     * **/
    _closeAgeDialog() {
      this.ageDialogVisible = false;
    },
    /**
     * 关闭登录
     * **/
    _closeLoginDialog(type) {
      this.loginDialogVisible = false;
      if (type === "reload") {
        this.reload();
      }
    }
  },
  created() {
    this.userSystemHide = Cookies.get("userSystemHide");
  }
};
</script>
<style lang="less">
.page-wrapper-box {
  width: 100%;
  height: 100vh;
  position: relative;
}
.home-left {
  position: absolute;
  right: 2%;
  bottom: 6%;
  z-index: 11;
  float: right !important;
  display: inline-block;
  vertical-align: top;
  text-align: center;

  // &:nth-of-type(2) {
  //   animation: bounceIn 0.5s linear 0.4s forwards;
  // }
  // &:nth-of-type(3) {
  //   animation: bounceIn 0.5s linear 0.4s forwards;
  // }
  .left-item {
    width: 100%;
    height: 33%;
    padding-top: 0.1rem;

    &:nth-of-type(1) {
      .img-left-item {
        background-image: url("../../assets/new_ui/new_home_history.png");
        background-size: 100% 100%;
        &:hover {
          background-image: url("../../assets/new_ui/new_home_history_hover.png");
          background-size: 100% 100%;
        }
      }
    }
    &:nth-of-type(2) {
      .img-left-item {
        background-image: url("../../assets/new_ui/new_home_collection.png");
        background-size: 100% 100%;
        &:hover {
          background-image: url("../../assets/new_ui/new_home_collection_hover.png");
          background-size: 100% 100%;
        }
      }
    }
    &:nth-of-type(3) {
      .img-left-item {
        background-image: url("../../assets/new_ui/new_home_age_group.png");
        background-size: 100% 100%;
        &:hover {
          background-image: url("../../assets/new_ui/new_home_age_group_hover.png");
          background-size: 100% 100%;
        }
      }
    }
    .img-left-item {
      margin-top: 0.15rem;
      width: 100%;
      height: 100%;
      width: 0.78rem;
      height: 0.7rem;
      background-size: 100% 100%;

      // &:hover {
      //   // filter: opacity(0.8);
      //   filter: saturate(120%) brightness(90%);
      // }
    }
    .p-left-item {
      margin-top: 0.15rem;
      font-size: 0.2rem;
      color: #fff;
      line-height: 0.2rem;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
.home-page-wrapper {
  width: 14.36rem;
  height: 6.9rem;
  margin: auto;
  .home-right {
    // display: inline-block;
    vertical-align: top;

    width: calc(100%);
    height: 100%;

    text-align: center;
    // line-height: 600px;
    position: relative;

    top: 1.5rem;
    z-index: 10;

    .type-block {
      // overflow: hidden;

      display: inline-block;
      position: absolute;

      width: 3.32rem;
      height: 2.4rem;

      &.type-block-12156 {
        left: 0px;
        top: 0.49rem;
      }
      &.type-block-12158 {
        left: 1rem;
        top: 3.7rem;
      }
      &.type-block-12157 {
        right: 5.5rem;
        bottom: -0.15rem;
      }
      &.type-block-12155 {
        right: 1rem;
        top: 3.7rem;
      }
      &.type-block-12159 {
        right: 0px;
        top: 0.49rem;
      }
      .img-type-block {
        width: 100%;
        &:hover {
          animation: tada 0.5s linear forwards;
        }
        .img-type-block1 {
        }
      }
    }
    .tree_img {
      width: 5.12rem;
      height: 4.76rem;
      position: relative;
      // top: 9%;
    }
  }
}
.ageType {
  width: 0.65rem;
  height: 0.38rem;
  font-size: 0.15rem;
  position: absolute;
  bottom: 0.77rem;
  left: -0.43rem;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
